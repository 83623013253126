import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Icon.css'

function Icon(props) {
    return (
        <FontAwesomeIcon className="icon" size="lg" icon={props.icon} color="white" />
    )
}

export default Icon