import React from 'react';
import './App.css';
import Icon from './Components/Icon/Icon'
import Div100vh from 'react-div-100vh'
import Comment from './Components/Comment'

function App() {
  return (
    <Div100vh id="app">
      <div id="content">
        <h1 id="title">You've found me on the internet!<br/>I make apps, not websites.</h1>
        <h2>Please visit or contact me below.</h2>
        <div id="links">
          <a href="https://github.com/EvanCooper9">
            <Icon icon={['fab', 'github']} />
          </a>
          <a href="https://apps.apple.com/us/developer/evan-cooper/id1512600688">
          <Icon icon={['fab', 'app-store']} />
          </a>
          <a href="https://linkedin.com/in/cooper-evan">
            <Icon icon={['fab', 'linkedin']} />
          </a>
          <a href="mailto:evan.cooper@rogers.com">
            <Icon icon="envelope" />
          </a>
        </div>
      </div>
      <Comment text="Hi Francis"/>
    </Div100vh>
  );
}

export default App;
